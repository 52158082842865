import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ShopSlice } from "../types/types";

const initialState: ShopSlice = { isMenuOpen: true }

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShopMenuStatus(state: ShopSlice, action: PayloadAction<{ status: boolean }>): void { state.isMenuOpen = action.payload.status }
    }
})

export const getMenuStatus = (state: RootState) => state.shop.isMenuOpen

export const { setShopMenuStatus } = shopSlice.actions
export default shopSlice.reducer