import React from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
import { HeaderProps } from '../types/types'
import { useWidth } from '../utils/hooks'
import { MOBILE_WIDTH } from '../utils/constants'
import '../app.css'

const Header = ({text = ''} : HeaderProps) => {
  let width = useWidth()
  return (
    <StyledFlexContainer  $padding='0px 10px' $height='max-content' $alignItems='center' $justifyContent='center' style={{borderRadius:'3px', minHeight: '100px', textAlign:'center', userSelect: 'none', backgroundColor: 'white', fontSize: width < MOBILE_WIDTH ? '1.4rem' : '1.8rem', fontFamily:'Kanit'}}> {text} </StyledFlexContainer>
  )
}

export default Header