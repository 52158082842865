import React, { useEffect, useState } from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
// eslint-disable-next-line
import { IconCamera, IconInstagram, IconLocation, IconMail, IconMenu, IconPencil, IconPinterest, IconUserSmile } from '../utils/icons'
import styled from 'styled-components'
import { useWidth } from '../utils/hooks'
import { MOBILE_WIDTH, TABLET_WIDTH } from '../utils/constants'
import { Link } from 'react-router-dom'
import { Tooltip } from '@chakra-ui/react'

export const StyledNavLink = styled.div<{ $isActive: boolean, $isSocial?: boolean }>` width: 100%; color: ${props => props.$isActive ? 'white' : props.$isSocial ? '#aeaeae' : '#4e4e4e'}; background-color: ${props => props.$isActive ? 'orange' : 'white'}; border-radius: 3px; font-weight: ${props => props.$isActive ? 'bold' : 'normal'}; text-decoration: none; height: 40px; display: flex; align-items: center; justify-content: flex-start; transition: all ease 300ms; letter-spacing: ${props => props.$isActive && '1px'}; text-transform: uppercase; padding: 0 5px; gap: 4px; &:visited{ color: ${props => props.$isActive ? 'white' : '#4e4e4e'}; } &:hover{ background-color: orange; color: white; letter-spacing: 1px; transition: all ease 300ms; } `
const StyledMenuIcon = styled.div` background-color: transparent; cursor: pointer; color: black; transition: all ease 300ms; padding-left: 10px; &:hover{ color: orange; transition: all ease 300ms; } `

const Navigation = () => {
    let width = useWidth()
    const [path, setPath] = useState<string>(window.location.pathname)
    const [isMenuOpen, setMenuStatus] = useState<boolean>(width > MOBILE_WIDTH)
    useEffect(() => { if (width > MOBILE_WIDTH) { setMenuStatus(true) } else setMenuStatus(false) }, [width])

    return (
        <StyledFlexContainer $dir='column' $height='100vh' $width={isMenuOpen ? '120px' : '50px'} style={{overflow: 'auto', backgroundColor: 'white', borderRight: '1px solid #e2e2e2', paddingTop: '20px', minWidth: isMenuOpen ? '120px' : '50px' }} $alignItems='flex-start' $justifyContent='flex-start'>
            <StyledMenuIcon onClick={() => { setMenuStatus(!isMenuOpen) }}><IconMenu width={'2em'} height={'2em'} /></StyledMenuIcon>
            <StyledFlexContainer style={{ backgroundColor: 'white', userSelect:'none' }} $padding='5px' $gap='5px' $dir='column' $justifyContent='flex-start' $alignItems='flex-start' $height='fit-content'>
                <Link onClick={() => { setPath('/'); width < MOBILE_WIDTH && setMenuStatus(false) }} style={{ textDecoration: 'none', width: isMenuOpen ? '110px' : 'max-content' }} to={'/'}> <StyledNavLink $isActive={path === '/' || path.includes('works')}> <IconCamera width={!isMenuOpen ? '2em' : '1em'} height={!isMenuOpen ? '2em' : '1em'} /> {isMenuOpen && 'works'}</StyledNavLink></Link>
                <Link onClick={() => { setPath('/about'); width < MOBILE_WIDTH && setMenuStatus(false) }} style={{ textDecoration: 'none', width: isMenuOpen ? '110px' : 'max-content' }} to={'/about'}> <StyledNavLink $isActive={path === '/about'}> <IconUserSmile width={!isMenuOpen ? '2em' : '1em'} height={!isMenuOpen ? '2em' : '1em'} /> {isMenuOpen && 'about'}</StyledNavLink></Link>
                <Link onClick={() => { setPath('/contact'); width < MOBILE_WIDTH && setMenuStatus(false) }} style={{ textDecoration: 'none', width: isMenuOpen ? '110px' : 'max-content' }} to={'/contact'}> <StyledNavLink $isActive={path === '/contact'}> <IconMail width={!isMenuOpen ? '2em' : '1em'} height={!isMenuOpen ? '2em' : '1em'} /> {isMenuOpen && 'contact'}</StyledNavLink></Link>
            </StyledFlexContainer>
            <StyledFlexContainer style={{ backgroundColor: 'white', userSelect:'none' }} $padding={width >= TABLET_WIDTH ? '0 0 40px 0' : width < TABLET_WIDTH && width >= MOBILE_WIDTH? '0 0 70px 0' : '0 0 90px 0'} $gap='5px' $dir='column' $justifyContent='flex-end' $alignItems='center' $height='100%'>
                <Tooltip label='Instagram'><a href='https://www.instagram.com/ashamadian.photo' rel="noreferrer" target='_blank' style={{ textDecoration: 'none', width: isMenuOpen ? '110px' : 'max-content' }}> <StyledNavLink $isSocial style={{ width: 'fit-content' }} $isActive={false}> <IconInstagram width={'2em'} height={'2em'} /> </StyledNavLink></a></Tooltip>
                {isMenuOpen && <div style={{display: 'flex', flexDirection:'column', alignItems: 'flex-start', justifyContent: 'flex-start', padding: 10}}> <div style={{fontSize:'0.7rem'}} >P.IVA</div> <div style={{fontSize:'0.7rem'}} > 12752310016</div> </div>}
                {/* <Tooltip label='Pinterest'><a href='https://www.pinterest.com' rel="noreferrer" target='_blank' style={{ textDecoration: 'none', width: isMenuOpen ? '110px' : 'max-content' }}> <StyledNavLink $isSocial style={{ width: 'fit-content' }} $isActive={false}> <IconPinterest width={'2em'} height={'2em'} /> </StyledNavLink></a></Tooltip> */}
            </StyledFlexContainer>
        </StyledFlexContainer>
    )
}

export default Navigation