import { useLayoutEffect, useState } from "react"

export const useWidth = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
    const handleSize = () => setWindowWidth(window.innerWidth)
    useLayoutEffect(() => {
        window.addEventListener('resize', handleSize)
        return () => window.removeEventListener('resize', handleSize)
    }, [])
    return windowWidth
}