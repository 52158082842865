import { configureStore } from "@reduxjs/toolkit";
import shopReducer from './shopSlice'

export const store = configureStore({
    reducer: {
        shop: shopReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export type RootState = ReturnType<typeof store.getState>