import React from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
import { useWidth } from '../utils/hooks'
import { Center, Divider, Image } from '@chakra-ui/react'
import aboutImage from '../assets/images/about.jpg'
import { MOBILE_WIDTH, TABLET_WIDTH } from '../utils/constants'
import PageWrapper from '../components/PageWrapper'

const AboutPage = () => {
  let width = useWidth()
  return (
    <PageWrapper headerText='Behind the Lens: Master of Capturing Personality in Pixels' paddingBottom={width < MOBILE_WIDTH ? 90 : width >= MOBILE_WIDTH && width < TABLET_WIDTH ? 70 : 20}>
      <StyledFlexContainer style={{ overflow: 'auto' }} $padding={width > TABLET_WIDTH ? '30px' : '10px'} $dir={width > TABLET_WIDTH ? 'row' : 'column'} $alignItems='flex-start' $justifyContent='flex-start'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%' }} >
          <p>Every face has it's story!</p>
          <Center width='100%'> <Divider orientation='horizontal' /> </Center>
          <p style={{ textAlign: 'justify' }}>Welcome to my world of portrait photography, where every face tells a story and every moment is a masterpiece. I’m Ash, a passionate portrait photographer dedicated to capturing the essence of individuality through my lens. With a keen eye for detail and a deep love for human connections, I transform ordinary moments into extraordinary memories. My approach is personalized, ensuring each session reflects the unique personality of my subjects. Whether in a studio or on location, my goal is to create images that resonate with emotion and authenticity. Let's embark on a visual journey together and frame your story beautifully.</p>
        </div>
        <Image boxSize='100%' sx={{ marginLeft: width > TABLET_WIDTH ? '20px' : '0px', borderRadius: '3px' }} objectFit='cover' objectPosition={'top'} src={aboutImage} alt='Dan Abramov' />
      </StyledFlexContainer>
    </PageWrapper>
  )
}

export default AboutPage