import React from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
import { IconFaceSad } from '../utils/icons'

const PageNotFound = () => {
  return (
    <StyledFlexContainer $padding='0 20px' $height='100vh' $alignItems='center' $justifyContent='center' $gap='20px' $dir='column'>
        <IconFaceSad width={'10em'} height={'10em'} />
        <p style={{fontSize: '1.6rem'}}>Sorry, the page you are searching for doesn't exsist! Try another one.</p>
    </StyledFlexContainer>
  )
}

export default PageNotFound