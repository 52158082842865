import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AboutPage, ContactPage, WorksPage, PageNotFound } from './pages';
import Layout from './layout/Layout';
import './app.css'

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<WorksPage />} />
          <Route path='/works' element={<WorksPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/about' element={<AboutPage />} />
        </Route>
        <Route path='/notfound' element={<PageNotFound />} />
        <Route path='*' element={<Navigate to={'/notfound'} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
