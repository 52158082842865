import * as React from 'react'
import { ICON_FONTSIZE } from './constants';

function IconHome(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props} > <path d="M946.5 505L534.6 93.4a31.93 31.93 0 00-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z" /> </svg> ); }
function IconShopping(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M12 13a5 5 0 01-5-5h2a3 3 0 003 3 3 3 0 003-3h2a5 5 0 01-5 5m0-10a3 3 0 013 3H9a3 3 0 013-3m7 3h-2a5 5 0 00-5-5 5 5 0 00-5 5H5c-1.11 0-2 .89-2 2v12a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2z" /> </svg> ); }
function IconBook(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 16 16" fill="currentColor" height="1em" width="1em" {...props} > <path fill="currentColor" d="M14 2v13H3.5a1.5 1.5 0 110-3H13V0H3C1.9 0 1 .9 1 2v12c0 1.1.9 2 2 2h12V2h-1z" /> <path fill="currentColor" d="M3.501 13H3.5a.5.5 0 000 1H12.999v-1H3.501z" /> </svg> ); }
function IconMail(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z" /> </svg> ); }
function IconBlogger(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M20.954 10.667c-.072-.322-.272-.621-.502-.745-.07-.039-.522-.088-1.004-.109-.809-.036-.898-.052-1.152-.201-.405-.237-.516-.493-.518-1.187-.002-1.327-.554-2.559-1.646-3.67-.776-.793-1.645-1.329-2.634-1.629-.236-.072-.768-.097-2.545-.118-2.787-.033-3.405.024-4.356.402-1.748.697-3.008 2.166-3.465 4.05-.087.353-.103.92-.124 4.177-.025 4.08.004 4.68.258 5.488.212.668.425 1.077.861 1.657.835 1.108 2.083 1.907 3.334 2.133.595.107 7.931.135 8.683.032 1.306-.178 2.331-.702 3.293-1.684.694-.71 1.129-1.479 1.414-2.499.117-.424.127-.63.149-3.117.017-1.878.002-2.758-.046-2.98zM8.007 8.108c.313-.316.399-.329 2.364-.329 1.764 0 1.822.004 2.081.134.375.189.538.456.538.88 0 .384-.153.653-.493.869-.184.115-.293.123-2.021.133-1.067.007-1.916-.013-2.043-.048-.669-.184-.918-1.143-.426-1.639zm7.706 8.037l-.597.098-3.114.035c-2.736.033-3.511-.018-3.652-.08-.288-.124-.554-.472-.602-.78-.042-.292.104-.696.33-.9.285-.257.409-.266 3.911-.27 3.602-.002 3.583-.003 3.925.315.482.45.381 1.251-.201 1.582z" /> </svg> ); }
function IconUserSmile(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path fill="none" d="M0 0h24v24H0z" /> <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 12a5 5 0 0010 0h-2a3 3 0 01-6 0H7z" /> </svg> ); }
function IconMenu(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path fill="none" d="M0 0h24v24H0z" /> <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" /> </svg> ); }
function IconRightArrow(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M19 12l-7-6v5H6v2h6v5z" /> </svg> ); }
function IconLeftArrow(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M5 12l7 6v-5h6v-2h-6V6z" /> </svg> ); }
function IconTiktok(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 448 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M448 209.91a210.06 210.06 0 01-122.77-39.25v178.72A162.55 162.55 0 11185 188.31v89.89a74.62 74.62 0 1052.23 71.18V0h88a121.18 121.18 0 001.86 22.17A122.18 122.18 0 00381 102.39a121.43 121.43 0 0067 20.14z" /> </svg> ); }
function IconInstagram(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} baseProfile="tiny" viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M12 3c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm2.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zM12 19c-3.9 0-7-3.1-7-7h3c0 2.2 1.8 4 4 4s4-1.8 4-4h3c0 3.9-3.1 7-7 7z" /> </svg> ); }
function IconYoutube(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M8.48 13.14h.73v3.61h.7v-3.61h.73v-.61H8.48zM12.17 16c-.12.14-.53.42-.53.02v-2.39h-.62v2.61c0 .79.79.58 1.16.17v.34h.62v-3.12h-.62V16h-.01zM14.48 13.61c-.36 0-.59.27-.59.27v-1.36h-.63v4.23h.63v-.24s.21.28.59.28c.33 0 .58-.29.58-.69v-1.73c0-.47-.22-.76-.58-.76zm-.07 2.41c0 .23-.16.34-.37.25a.479.479 0 01-.15-.11v-1.94c.04-.04.09-.07.13-.1.22-.11.39.06.39.29v1.61zM16.72 15.86c0 .24-.13.4-.28.41-.16.01-.32-.12-.32-.41v-.59h1.19v-.8a.91.91 0 00-.26-.66.919.919 0 00-.63-.24c-.22 0-.45.07-.63.21-.19.15-.31.38-.31.69v1.4c0 .28.09.5.23.66.17.18.4.28.64.29.29.01.6-.11.78-.36.11-.15.18-.35.18-.59v-.16h-.59v.15zm-.6-1.39c0-.17.1-.37.29-.37s.31.18.31.37v.32h-.6v-.32z" /> <path d="M12.97 3a9 9 0 10.001 18.001A9 9 0 0012.97 3zm1.58 3.37h.8v2.68c0 .17.08.17.11.17.12 0 .3-.13.39-.22V6.36h.8V9.9h-.8v-.31c-.11.1-.22.18-.33.24-.15.08-.29.11-.43.11a.52.52 0 01-.41-.17c-.09-.11-.13-.28-.13-.49V6.37zM12 7.3c0-.55.45-1 1-1s1 .45 1 1V9c0 .55-.45 1-1 1s-1-.45-1-1V7.3zM9.92 5.15l.48 1.76.49-1.76h.91l-.94 2.78V9.9h-.89V7.93l-.96-2.78h.91zm7.9 12.54c-.51.5-4.83.51-4.83.51s-4.31-.01-4.83-.51c-.51-.5-.51-2.99-.51-3.01 0-.01 0-2.5.51-3.01.51-.5 4.83-.51 4.83-.51s4.31.01 4.83.51c.51.5.52 2.99.52 3.01 0 0 0 2.5-.52 3.01z" /> <path d="M12.98 9.35c.17 0 .25-.1.26-.26v-1.9c0-.13-.13-.24-.25-.24s-.25.1-.25.24v1.9c0 .15.07.25.24.26z" /> </svg> ); }
function IconPinterest(props: React.SVGProps<SVGSVGElement>) { return ( <svg fontSize={props.fontSize ? props.fontSize : ICON_FONTSIZE} viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M9.04 21.54c.96.29 1.93.46 2.96.46a10 10 0 0010-10A10 10 0 0012 2 10 10 0 002 12c0 4.25 2.67 7.9 6.44 9.34-.09-.78-.18-2.07 0-2.96l1.15-4.94s-.29-.58-.29-1.5c0-1.38.86-2.41 1.84-2.41.86 0 1.26.63 1.26 1.44 0 .86-.57 2.09-.86 3.27-.17.98.52 1.84 1.52 1.84 1.78 0 3.16-1.9 3.16-4.58 0-2.4-1.72-4.04-4.19-4.04-2.82 0-4.48 2.1-4.48 4.31 0 .86.28 1.73.74 2.3.09.06.09.14.06.29l-.29 1.09c0 .17-.11.23-.28.11-1.28-.56-2.02-2.38-2.02-3.85 0-3.16 2.24-6.03 6.56-6.03 3.44 0 6.12 2.47 6.12 5.75 0 3.44-2.13 6.2-5.18 6.2-.97 0-1.92-.52-2.26-1.13l-.67 2.37c-.23.86-.86 2.01-1.29 2.7v-.03z" /> </svg> ); }
function IconFaceSad(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M352 493.4c-29.6 12-62.1 18.6-96 18.6s-66.4-6.6-96-18.6V288c0-8.8-7.2-16-16-16s-16 7.2-16 16v189.8C51.5 433.5 0 350.8 0 256 0 114.6 114.6 0 256 0s256 114.6 256 256c0 94.8-51.5 177.5-128 221.8V288c0-8.8-7.2-16-16-16s-16 7.2-16 16v205.4zM195.2 233.6c5.3 7.1 15.3 8.5 22.4 3.2s8.5-15.3 3.2-22.4c-30.4-40.5-91.2-40.5-121.6 0-5.3 7.1-3.9 17.1 3.2 22.4s17.1 3.9 22.4-3.2c17.6-23.5 52.8-23.5 70.4 0zm121.6 0c17.6-23.5 52.8-23.5 70.4 0 5.3 7.1 15.3 8.5 22.4 3.2s8.5-15.3 3.2-22.4c-30.4-40.5-91.2-40.5-121.6 0-5.3 7.1-3.9 17.1 3.2 22.4s17.1 3.9 22.4-3.2zM208 336v32c0 26.5 21.5 48 48 48s48-21.5 48-48v-32c0-26.5-21.5-48-48-48s-48 21.5-48 48z" /> </svg> ); }
function IconClose(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 21 21" fill="currentColor" height="1em" width="1em" {...props} > <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" > <path d="M7.5 7.5l6 6M13.5 7.5l-6 6" /> </g> </svg> ); }
function IconPencil(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5.2-23.7-6.1s-8.6-15.2-6.1-23.7l37.7-128.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9.9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16v-46.1zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1-34-33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z" /> </svg> ); }
function IconLocation(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 384 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M215.7 499.2C267 435 384 279.4 384 192 384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2 12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64z" /> </svg> ); }
function IconShare(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props} > <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304c0 113.3 81.5 163.9 100.2 174.1 2.5 1.4 5.3 1.9 8.1 1.9 10.9 0 19.7-8.9 19.7-19.7 0-7.5-4.3-14.4-9.8-19.5-9.4-8.9-22.2-26.4-22.2-56.8 0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144a31.76 31.76 0 00-34.4-5.4z" /> </svg> ); }
function IconCamera(props: React.SVGProps<SVGSVGElement>) { return ( <svg baseProfile="tiny" viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path d="M19 6h-1.586l-1-1c-.579-.579-1.595-1-2.414-1h-4c-.819 0-1.835.421-2.414 1l-1 1H5C3.346 6 2 7.346 2 9v8c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3V9c0-1.654-1.346-3-3-3zm-7 10a3.5 3.5 0 11.001-7.001A3.5 3.5 0 0112 16zm6-4.701a1.3 1.3 0 110-2.6 1.3 1.3 0 010 2.6z" /> </svg> ); }
function IconCopy(props: React.SVGProps<SVGSVGElement>) { return ( <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} > <path fill="none" d="M0 0h24v24H0z" /> <path d="M7 6V3a1 1 0 011-1h12a1 1 0 011 1v14a1 1 0 01-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 013 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" /> </svg> ); }

export { IconBlogger, IconCopy, IconPencil, IconCamera, IconShare, IconClose, IconFaceSad, IconLocation, IconLeftArrow, IconInstagram, IconPinterest, IconTiktok, IconYoutube, IconRightArrow, IconBook, IconMenu, IconHome, IconMail, IconShopping, IconUserSmile }