import React, { useEffect, useRef, useState } from 'react'
import { StyledActionBtn, StyledFlexContainer } from '../utils/styles.common'
import { sendForm } from '@emailjs/browser'
import { useWidth } from '../utils/hooks';
import styled from 'styled-components';
import { Alert, AlertIcon, Image, Spinner } from '@chakra-ui/react';
import contactImage from '../assets/images/contact.jpg'
import { MOBILE_WIDTH, TABLET_WIDTH } from '../utils/constants';
import PageWrapper from '../components/PageWrapper';
import { IconLocation } from '../utils/icons';

const StyledInput = styled.input` padding: 3px 5px; height: 35px; border: none; border-bottom: 1px solid #d7d7d7; transition: all ease 100ms; &:focus{ outline: none;  border-bottom: 2px solid orange } `
const StyledTextarea = styled.textarea` padding: 3px 5px; height: 35px; min-height: 120px; max-height: 250px; border: 1px solid #d7d7d7; transition: border ease 100ms; border-radius: 2px; &:focus{ outline: none; border: 2px solid orange; } `

const ContactPage = () => {
  let width = useWidth()
  const form = useRef<HTMLFormElement>(null);
  const [showSpinner, setSpinnerStatus] = useState<boolean>(false)
  const [showAlert, setAlertStatus] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>('')
  const [alertVariant, setAlertVariant] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  // eslint-disable-next-line
  let timeout: any;
  useEffect(() => {
    // eslint-disable-next-line
    if (showAlert) timeout = setTimeout(() => { setAlertStatus(false) }, 3000)
    return () => clearInterval(timeout)
  }, [showAlert])

  const isSendDisabled = () => { if (name.length === 0 || email.length === 0 || message.length === 0) return true; else return false; }
  const clearForm = () => { setName(''); setEmail(''); setMessage('') }
  const sendEmail = (e: any) => {
    setSpinnerStatus(true)
    e.preventDefault();
    sendForm('service_e5pk3jd', 'template_wcfo02s', form.current as HTMLFormElement, { publicKey: 'XaVLM8jwcTYbh-slA', })
      .then(() => { e.target.reset(); clearForm(); setAlertVariant('success'); setAlertStatus(true); setAlertText('Your message has been sent successfully!!!') }, (error) => { console.log('FAILED...', error.text); e.target.reset(); clearForm(); setAlertVariant('error'); setAlertStatus(true); setAlertText('There is an error, please try again later!!!') },)
      .finally(() => { setSpinnerStatus(false) })
  };

  return (
    <PageWrapper headerText={`Let's Frame Your Story: Reach Out and Let's Create Together!`} paddingBottom={width < MOBILE_WIDTH ? 90 : width >= MOBILE_WIDTH && width < TABLET_WIDTH ? 70 : 20}>
      <StyledFlexContainer style={{ overflow: 'auto' }} $padding={width > TABLET_WIDTH ? '30px' : '10px'} $dir={width > TABLET_WIDTH ? 'row' : 'column'} $alignItems='flex-start' $justifyContent='flex-start'>
        <form style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }} ref={form} onSubmit={sendEmail}>
          <StyledInput value={name} onChange={(e) => setName(e.target.value)} required placeholder='Name' type="text" name="from_name" />
          <StyledInput value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='Email address' type="email" name="user_email" />
          <StyledTextarea value={message} onChange={(e) => setMessage(e.target.value)} required placeholder='Message' name="message" />
          <StyledActionBtn disabled={isSendDisabled()} $disabled={isSendDisabled()} style={{ cursor: isSendDisabled() ? 'default' : 'pointer' }} type='submit' value="Send" > Send </StyledActionBtn>
          <div style={{display: 'flex', alignItems: 'baseline', gap: 5}}><div><IconLocation color='orange' /></div> <div style={{fontSize: '0.8rem'}}>Torino, Strada di Superga 10132</div></div>
        </form>
        <Image boxSize='100%' sx={{ marginLeft: width > TABLET_WIDTH ? '20px' : '0px', borderRadius: '3px' }} objectFit='cover' objectPosition={'top'} src={contactImage} alt='Dan Abramov' />
      </StyledFlexContainer>
      {showSpinner && <StyledFlexContainer $dir='column' style={{ position: 'fixed' }} $alignItems='center' $justifyContent='center'><Spinner color='orange' thickness='5px' speed='0.5s' size='xl' /> wait... </StyledFlexContainer>}
      {showAlert && <StyledFlexContainer $width='fit-content' $alignItems='flex-end' $justifyContent='flex-start'> <Alert status={alertVariant as "loading" | "success" | "error" | "info" | "warning" | undefined}> {alertText} <AlertIcon /> </Alert> </StyledFlexContainer>}
    </PageWrapper>
  )
}

export default ContactPage