import React from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
import Header from './Header'
import { Center, Divider } from '@chakra-ui/react'
import { PageWrapperProps } from '../types/types'

const PageWrapper = ({ children = <></>, headerText = '', paddingBottom = 20 }: PageWrapperProps) => {
    return (
        <StyledFlexContainer $alignItems='center' $justifyContent='flex-start' $height={`calc(100vh - ${paddingBottom}px)`} $dir='column' style={{ backgroundColor: 'white', borderRadius: '3px' }} $margin='10px' $padding='10px'>
            <Header text={headerText} />
            <Center width='100%'> <Divider orientation='horizontal' /> </Center>
            {children}
        </StyledFlexContainer>
    )
}

export default PageWrapper