import React, { useEffect, useState } from 'react'
import { StyledFlexContainer } from '../utils/styles.common'
// eslint-disable-next-line
import { FacebookShareButton, PinterestShareButton, EmailShareButton, WhatsappShareButton, TelegramShareButton, FacebookIcon, WhatsappIcon, PinterestIcon, EmailIcon, TelegramIcon } from 'react-share'
import { useWidth } from '../utils/hooks'
import styled from 'styled-components'
import { ImageDataType, ScreenSizes } from '../types/types'
import { MOBILE_WIDTH, TABLET_WIDTH } from '../utils/constants'
import PageWrapper from '../components/PageWrapper'
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconCopy, IconInstagram, IconShare } from '../utils/icons'
import { StyledNavLink } from '../components/Navigation'
import { useSearchParams } from 'react-router-dom'
import { data } from '../app/data'

const StyledImageContainer = styled.div`
  &::-webkit-scrollbar{
    display: none;
  }
`
const StyledThumbnailContainer = styled.div<{ $isSelected: boolean }>` width: 60px; height: 60px; max-width: 100%; background-color: gray; overflow: hidden; opacity: ${props => props.$isSelected ? 1 : 0.7}; cursor: pointer; transition: opacity ease 200ms; &:hover{ opacity: 1; } `
const StyledWorkContainer = styled.div<{ $screen?: ScreenSizes }>` min-height: 300px; min-width: ${props => props.$screen === 'DESKTOP' ? '33%' : props.$screen === 'TABLET' ? '50%' : '100%'}; width: 100%; height: 100%; border-radius: 3px; cursor: pointer; background-color: gray; transition: opacity ease 200ms; &:hover{ opacity: ${props => props.$screen === 'DESKTOP' && '1'}; } `
const StyledStoreButton = styled.div<{ $isDesktop?: boolean }>` cursor: pointer; width: max-content; border-radius: 20px; height: max-content; padding: 10px; background-color: #fff; box-shadow: 1px 1px 2px #00000060; opacity: ${props => props.$isDesktop ? 0.7 : 1} ; `

const WorksPage = () => {

  const [search] = useSearchParams()
  useEffect(() => {
    if (search.get('id') !== null) {
      setSelectedImage(data.find((image) => image.id === search.get('id')))
      onOpen()
    }
    // eslint-disable-next-line
  }, [search])

  let width = useWidth()
  const btnRef = React.useRef(null)

  const [dataArray, setDataArray] = useState<ImageDataType[][]>([])
  const [selectedImage, setSelectedImage] = useState<ImageDataType>()
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(0)
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const screenSize = (): ScreenSizes => {
    let screen: ScreenSizes = ''
    width < MOBILE_WIDTH && (screen = 'MOBILE');
    width >= MOBILE_WIDTH && width < TABLET_WIDTH && (screen = 'TABLET');
    width >= TABLET_WIDTH && (screen = 'DESKTOP');
    return screen
  }

  useEffect(() => {
    let desktopArr: ImageDataType[][] = [[], [], []]
    let tabletArr: ImageDataType[][] = [[], []]
    let mobileArr: ImageDataType[][] = [[]]
    for (let i = 0; i < data.length; i++) { if (i % 3 === 0) { desktopArr[0].push(data[i]) } else if (i % 3 === 1) { desktopArr[1].push(data[i]) } else { desktopArr[2].push(data[i]) } }
    for (let i = 0; i < data.length; i++) { if (i % 2 === 0) { tabletArr[0].push(data[i]) } else { tabletArr[1].push(data[i]) } }
    for (let i = 0; i < data.length; i++) { mobileArr[0].push(data[i]) }
    screenSize() === 'DESKTOP' && setDataArray(desktopArr)
    screenSize() === 'TABLET' && setDataArray(tabletArr)
    screenSize() === 'MOBILE' && setDataArray(mobileArr)
    // eslint-disable-next-line
  }, [screenSize()])

  useEffect(() => {
    const handleContextmenu = (e: any) => { e.preventDefault() }
    document.addEventListener('contextmenu', handleContextmenu)
    return () => { document.removeEventListener('contextmenu', handleContextmenu) }
  }, [])

  const ShareMenu = () => {
    let shareLink = `https://ashamadian.design/works?id=${selectedImage?.id}`
    return (
      <Modal isCentered isOpen={modalStatus} onClose={() => setModalStatus(false)}>
        <ModalOverlay />
        <ModalContent style={{ minHeight: '170px', maxWidth: '95%', width: 'fit-content' }}>
          <ModalHeader>Share on</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
            <FacebookShareButton url={shareLink} style={{ fontSize: '0.75rem', flexDirection: 'column', gap: 10, display: 'flex', alignItems: 'center' }}> <StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><FacebookIcon size={40} round /> </StyledStoreButton> Facebook </FacebookShareButton>
            {/* <PinterestShareButton media={selectedImage?.shareImage} url={shareLink} style={{ fontSize: '0.75rem', flexDirection: 'column', gap: 10, display: 'flex', alignItems: 'center' }}> <StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><PinterestIcon size={40} round />  </StyledStoreButton> Pinterest </PinterestShareButton> */}
            <WhatsappShareButton url={shareLink} style={{ fontSize: '0.75rem', flexDirection: 'column', gap: 10, display: 'flex', alignItems: 'center' }}> <StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><WhatsappIcon size={40} round /> </StyledStoreButton> WhatsApp </WhatsappShareButton>
            <TelegramShareButton url={shareLink} style={{ fontSize: '0.75rem', flexDirection: 'column', gap: 10, display: 'flex', alignItems: 'center' }}> <StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><TelegramIcon size={40} round /> </StyledStoreButton> Telegram </TelegramShareButton>
            <EmailShareButton url={shareLink} style={{ fontSize: '0.75rem', flexDirection: 'column', gap: 10, display: 'flex', alignItems: 'center' }}> <StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><EmailIcon size={40} round /> </StyledStoreButton> Email </EmailShareButton>
            <div onClick={() => {navigator.clipboard.writeText(shareLink); setModalStatus(false)}} style={{ fontSize: '0.75rem', flexDirection: 'column', display: 'flex', gap: 10, alignItems: 'center' }}><StyledStoreButton $isDesktop={screenSize() === 'DESKTOP'} ><IconCopy color='orange' width={'2.8em'} height={'2.8em'} /> </StyledStoreButton> Copy link </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <PageWrapper headerText='Gallery of Portraiture: Explore My Lens' paddingBottom={width < MOBILE_WIDTH ? 210 : width >= MOBILE_WIDTH && width < TABLET_WIDTH ? 190 : 160}>
      <StyledFlexContainer $dir={'column'} >
        <StyledFlexContainer $width='100%' style={{ overflow: 'auto' }} $padding='10px' $dir='row' $gap='5px'>
          {dataArray.map((array, index) => (
            <StyledFlexContainer key={index} $height='100%' style={{ maxWidth: screenSize() === 'DESKTOP' ? '33%' : screenSize() === 'TABLET' ? '50%' : '100%', width: screenSize() === 'DESKTOP' ? '33%' : screenSize() === 'TABLET' ? '50%' : '100%', height: '100%' }}>
              <StyledFlexContainer $dir='column' $width='100%' $height={'max-content'} $gap='5px' $alignItems='flex-start'>
                {array.map((item, index2) => (
                  <StyledWorkContainer draggable={false} key={index2} ref={btnRef} onClick={() => { onOpen(); setSelectedImage(item); }}> <img draggable={false} width='100%' height='100%' src={item.images[0]} alt="" /> </StyledWorkContainer>
                ))}
              </StyledFlexContainer>
            </StyledFlexContainer>
          ))}
        </StyledFlexContainer >
      </StyledFlexContainer>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={() => { onClose(); setSelectedImage(undefined); setSelectedThumbnail(0) }}
        finalFocusRef={btnRef}
        size={'lg'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader><div><Button onClick={() => setModalStatus(true)} leftIcon={<IconShare />} colorScheme='orange' variant='solid'> Share </Button></div></DrawerHeader>
          <DrawerBody>
            <StyledFlexContainer $dir={screenSize() !== 'DESKTOP' ? 'column' : 'row'}>
              <StyledImageContainer draggable={false} style={{ height: 'fit-content', width: 'fit-content', backgroundColor: 'gray' }}> <StyledImageContainer draggable={false} id='img-cont' style={{ maxHeight: 'fit-content', overflow: 'auto' }} > <img width={screenSize() === 'DESKTOP' ? 450 : '100%'} src={selectedImage?.images[selectedThumbnail]} alt="" /> </StyledImageContainer> </StyledImageContainer>
              <StyledFlexContainer style={{ flexWrap: 'wrap' }} $height='100%' $width='fit-content' $dir={screenSize() === 'DESKTOP' ? 'column' : 'row'}>
                {selectedImage &&
                  selectedImage.images.map((image, index) => (
                    <StyledThumbnailContainer draggable={false} $isSelected={index === selectedThumbnail} onClick={() => setSelectedThumbnail(index)} key={index} > <img src={image} alt="" /> </StyledThumbnailContainer>
                  ))
                }
              </StyledFlexContainer>
              {/* <Center width='100%'> <Divider orientation='horizontal' /> </Center> */}
            </StyledFlexContainer>
          </DrawerBody>
          {selectedImage && selectedImage.social.length > 0 && <DrawerFooter borderTop={'1px solid #00000015'} flexDirection='row' paddingBottom={'10px'} gap={2} alignItems={'center'} justifyContent={'flex-start'}>
            <div>Follow the model on instagram:</div>
            <Tooltip label='Instagram'><a href={selectedImage.social} rel="noreferrer" target='_blank' style={{ textDecoration: 'none', width: '110px' }}> <StyledNavLink $isSocial style={{ width: 'fit-content' }} $isActive={false}> <IconInstagram width={'2em'} height={'2em'} /> </StyledNavLink></a></Tooltip>
          </DrawerFooter>}
        </DrawerContent>
      </Drawer>
      <ShareMenu />
    </PageWrapper>
  )
}

export default WorksPage