import { ImageDataType } from "../types/types";

export const data: ImageDataType[] = [
    {
      id: '0',
      title: "Lubna",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/011.jpg?alt=media&token=13f624be-432f-4340-be48-3a415ffdb2c9',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/012.jpg?alt=media&token=3f113fa7-bd91-4b06-8830-cee13087cedf',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/013.jpg?alt=media&token=2e404da4-80b8-4c51-91f8-0723d3f7b54f',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/014.jpg?alt=media&token=7773da1e-e150-49c5-91ba-e1421b7a24de',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/015.jpg?alt=media&token=f2f503d4-7c08-4aed-bd40-4bfc50f86429',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/016.jpg?alt=media&token=ce0e2052-07f9-4884-be6b-cd5e318f4b5d',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/017.jpg?alt=media&token=87d4279f-f861-4113-8942-70c7b88b8ffa',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/018.jpg?alt=media&token=c90b5d1a-4da6-4960-920c-240fe5493237'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/iamlubnab"
    },
    {
      id: '1',
      title: "Fateme",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/021.jpg?alt=media&token=c6c091b6-3d87-451c-810f-3e3f942f7a24',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/022.jpg?alt=media&token=2bf9f44e-caf8-493a-8eb1-c194f3d37a1c',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/023.jpg?alt=media&token=12cc1765-5ef4-4705-8480-2c6694326263',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/024.jpg?alt=media&token=5a1579a0-f096-4a6c-ba98-ee90bbb356d0'
      ],
      desc: "Desc1",
      social: ""
    },
    {
      id: '2',
      title: "Donya",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/031.jpg?alt=media&token=40793fde-85a4-45f7-8f12-2cf46a47ee50',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/032.jpg?alt=media&token=d1270d2a-c3fb-4c32-99ba-1376e87bf308',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/033.jpg?alt=media&token=467c17fb-8df6-44e5-a861-790819365a49'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/itsdonya_"
    },
    {
      id: '3',
      title: "Alessandra",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/041.jpg?alt=media&token=cab1178e-7836-4f55-aaf7-fd97ec3477b7',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/042.jpg?alt=media&token=cd335c79-c04c-4cdc-8427-984a2d897732',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/043.jpg?alt=media&token=ea0f9ca8-1f3a-4f4b-b185-ae81594c1a34',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/044.jpg?alt=media&token=d8e80608-e75f-445c-9627-bfbc09801d5d'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/alebass_"
    },
    {
      id: '4',
      title: "Donya",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/051.jpg?alt=media&token=67c4ee17-b1e6-4587-a8d2-836c721a28e7',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/052.jpg?alt=media&token=8db1e5fd-dd38-4a44-b90c-e34d36c632ff',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/053.jpg?alt=media&token=1849ea26-5951-44c8-a2b9-8f8537116cbb',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/054.jpg?alt=media&token=ad9d63c6-7a92-4cf2-b92c-db315b5eae96'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/itsdonya_"
    },
    {
      id: '5',
      title: "Fateme",
      images: [
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/061.jpg?alt=media&token=0be8d1f8-2236-4fa9-a003-bea609713b89',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/062.jpg?alt=media&token=68604b7a-ed94-4243-8771-3370e5e3a233'
      ],
      desc: "Desc1",
      social: ""
    },
    {
      id: '6',
      title: "Lubna",
      images:[
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/101.jpg?alt=media&token=bc34cf99-9f73-4957-a2f0-01a51c8359a4',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/102.jpg?alt=media&token=7b82c272-a62d-4d8c-8a1b-18db2cd9ebf8',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/103.jpg?alt=media&token=edb30678-ea90-4cd6-ab32-c3b4cf02fd7e',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/104.jpg?alt=media&token=9bbaa010-ade4-489d-97fa-ebf58e575dee'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/iamlubnab"
    },
    {
      id: '7',
      title: "Fateme",
      images: ['https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/071.jpg?alt=media&token=39988353-33ef-4aac-870f-9552e6f121d2'],
      desc: "Desc1",
      social: ""
    },
    {
      id: '8',
      title: "Fateme",
      images: ['https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/081.jpg?alt=media&token=32048065-aa43-4f3d-8a1f-4bf720c9a48c'],
      desc: "Desc1",
      social: ""
    },
    {
      id: '9',
      title: "Lubna",
      images: [
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/091.jpg?alt=media&token=a95e5385-54ea-4ffb-9bb5-a0fa06d267b0',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/092.jpg?alt=media&token=12d53811-c105-4628-9c53-69873002bccd',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/093.jpg?alt=media&token=7190536c-f878-4734-9bba-06b717f3153a',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/094.jpg?alt=media&token=786c95de-d57e-4fcb-ac44-0239b8464141',
        'https://firebasestorage.googleapis.com/v0/b/ash-amadian-design.appspot.com/o/095.jpg?alt=media&token=19b2f688-ee26-4d72-8a11-947fb4743b56'
      ],
      desc: "Desc1",
      social: "https://www.instagram.com/iamlubnab"
    },
  
  ]