import styled from "styled-components";
import { AlignType, DirectionType } from "../types/types";

export const StyledFlexContainer = styled.div<{
    $width?: string, $height?: string, $dir?: DirectionType, $alignItems?: AlignType, $justifyContent?: AlignType, $gap?: string, $padding?: string, $margin?: string
}>` 
width: ${props => props.$width ?? '100%'}; height: ${props => props.$height ?? '100%'}; display: flex; flex-direction: ${props => props.$dir ? props.$dir : 'row'}; align-items: ${props => props.$alignItems ? props.$alignItems : 'flex-start'}; justify-content: ${props => props.$justifyContent ? props.$justifyContent : 'flex-start'}; gap: ${props => props.$gap ? props.$gap : '5px'}; padding: ${props => props.$padding ? props.$padding : '0px'}; margin: ${props => props.$margin ? props.$margin : '0px'}; font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 

&::-webkit-scrollbar{
    display: none;
}
`
export const StyledActionBtn = styled.button<{ $disabled?: boolean }>` min-width: max-content; background-color: ${props => props.$disabled ? '#c9c9c9' : 'orange'}; border: none; border-radius: 3px; padding: 5px 15px; font-size: 1rem; color: white; cursor:  ${props => !props.$disabled ? 'default' : 'pointer'}; transition: ease all 200ms; &:hover{ background-color: ${props => props.$disabled ? '#c9c9c9' : 'white'}; color: ${props => props.$disabled ? 'white' : 'orange'}; transition: ease all 200ms; } `
